import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Title from 'components/title';
import Gallery from 'components/gallery';
import { graphql } from 'gatsby';
import Footer from 'components/footer/Footer';
import './index.css';
import { Copy } from '../components/gallery/item/item.css';
import { ThemeProvider } from 'styled-components';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { source, sourceLight } from './index.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { navigate } from '@reach/router';
import { lang } from '../store/languageContext';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Source Sans Pro, Arial',
    htmlFontSize: 11,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [source, sourceLight],
      },
    },
  },
});
theme.typography.sourceLight = {
  fontSize: '1.3rem',
  '@media (min-width:600px)': {
    fontSize: '1.6rem',
  },
};
const Index = ({ data }) => {

  if (typeof window !== 'undefined') {
    lang.culture = window.navigator.language;
    navigate(lang.langRoute(lang.culture));
  }

  const dataContent = data.allFile.edges[0].node.childHomeJson;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Layout>
          <div
            className="layout-div"
            style={{
              background: 'url({dataContent.imageBackground.publicURL})',
            }}
          >
            <figure>
              <a href="/">
                <img
                  src={dataContent.imageBackground.publicURL}
                  alt={'home background'}
                  width={'100%'}
                />
              </a>
              <figcaption className={'background-figcaption'}>
                <Title as="h2" size="large">
                  <div
                    className="source"
                    dangerouslySetInnerHTML={{
                      __html: dataContent.content.childMarkdownRemark.html,
                    }}
                  />
                </Title>
                <Copy className="source-light">
                  {dataContent.annotationTitle}
                </Copy>
              </figcaption>
            </figure>
          </div>
          <Gallery items={dataContent.gallery}/>
          <Footer footers={dataContent.footers}/>
        </Layout>
      </CssBaseline>
    </ThemeProvider>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
query {
  allFile (filter: {
    name: { eq : "home" }
  }) {
    edges {
      node {
        dir
        name
        extension
        relativeDirectory
        childHomeJson {
          title
          annotationTitle
          content {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          imageBackground {
            publicURL
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          gallery {
            title
            copy
            url
            image {
              childImageSharp {
                fluid(maxHeight: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          footers {
          	title
            description {
              label
              link
            }
          }
        }
      }
    }
  }
}
`;
